import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import LoginIcon from '@mui/icons-material/Login'

import { Link } from "react-router-dom";

import logo from '../../assets/images/logo.png';
//import avatar from '../assets/images/2.jpg';

const pages = [[0,'Home','/home'],[1,'About','/about-us'],[2,'Gallery ','/gallery'], [3,' MPSGallery ','/gallery/gallery-one'], [4,'Events','/events'], [5,'Blogs','/blogs'], [6,'ContactUs','/contact-us']];
const settings = [[0,'Profile','/profile'], [1,'Account', '/account'], [2,'Dashboard', '/dashboard'], [3,'Logout','/login']];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null | HTMLElement);
  const [anchorElUser, setAnchorElUser] = React.useState(null | HTMLElement);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
     <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
			<Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
			  minWidth:'89px',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >		  
            <img src={logo} id="web_main_logo" alt="MPS" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          </Typography>
         
		
          <Typography
            variant="h6"
            noWrap
            component="a"
			className="logoText"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
		  
            Modern Public School
			
          </Typography>

         
		   

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} id="mobile_main_logo" alt="MPS" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
             <Link to={page[2]}> <Button
                key={page[1]}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page[1]}
              </Button>
			  </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
           
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting[2]} onClick={handleCloseUserMenu}>
                 <Link to={setting[2]}><Typography textAlign="center">{setting[1]}</Typography></Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
		   <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page[2]} onClick={handleCloseNavMenu}>
                  <Link to={page[2]}>  <Typography textAlign="center">{page[1]}</Typography> </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
		   <Tooltip title="Open settings">
              <IconButton className="loginButtonHome" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/*<Avatar alt="Profile" src={avatar} />*/}
				<Link to="/login"><LoginIcon/></Link>
              </IconButton>
            </Tooltip>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;