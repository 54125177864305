import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import ResponsiveAppBar from './ResponsiveAppBar';
import { IconContext } from "react-icons/lib";

import "../../assets/css/sidebar.css"; 
import shyam from "../../assets/images/shyam3.png";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  CssBaseline,
  Drawer,
  //Typography
} from "@material-ui/core";
/*
import {
  Apps,
  Menu,
  ContactMail,
  AssignmentInd,
  Home
} from "@mui/icons-material";
*/
import {Menu} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 250,
    height: "100%",
	opacity: '0.7',
	background:'#0EC7A7',
	transition: '350ms'
  },
  avatar: {
    margin: "0.1rem auto",
    padding: "0.5rem",
    width: theme.spacing(19),
    height: theme.spacing(19)
  },
  listItem: {
    color: "tan"
  }
}));

const NavIcon = styled(Link)`
margin-left: 2rem;
font-size: 2rem;
height: 80px;
display: flex;
justify-content: flex-start;
align-items: center;
`;
/*
const SidebarNav = styled.nav`
background: #15171c;
width: 250px;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
transition: 350ms;
z-index: 10;
`;
*/
const SidebarWrap = styled.div`
width: 100%;
`;
export default function MenuBar() 
{
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	
	const toggleSlider = () => {
		setOpen(!open);
	};
	  
	//const [sidebar, setSidebar] = useState(false);
	//const showSidebar = () => setSidebar(!sidebar);
	const sideList = () => (						  
		<Box className={classes.menuSliderContainer} component="div">
		<NavIcon to="#a">
			<AiIcons.AiOutlineClose onClick={toggleSlider} />
		</NavIcon>
		<Avatar
			className={classes.avatar}			
			src={shyam}
			alt="Juaneme8"
		/>
		<Divider />  			
		<SidebarWrap>			
			{SidebarData.map((item, index) => {
			return <SubMenu item={item} key={index} />;
			})}
		</SidebarWrap>			
	</Box>
);
return (
    <>
	<IconContext.Provider value={{ color: "#fff" }}>
      <CssBaseline />

      <Box component="nav">
        <AppBar position="static">
        	 <AppBar position="static">
				 <Toolbar disableGutters style={{width:"100%"}}>	  
				   <IconButton onClick={toggleSlider}>
					  <Menu />
					</IconButton>
					<ResponsiveAppBar/>	
				  </Toolbar>	
			 </AppBar>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              {sideList()}			  
            </Drawer>
        
        </AppBar>
      </Box>
	  </IconContext.Provider>
    </>
  );
}