export const photos = [
  {
    src: "../img/gallery/1.jpeg",
    width: 4,
    height: 3
  },
  {
    src: "../img/gallery/2.jpeg",
    width: 1,
    height: 1
  },
  {
    src: "../img/gallery/3.jpeg",
    width: 3,
    height: 4
  },
  {
    src: "../img/gallery/4.jpeg",
    width: 3,
    height: 4
  },
  {
    src: "../img/gallery/5.jpeg",
    width: 3,
    height: 4
  },
  {
    src: "../img/gallery/6.jpeg",
    width: 4,
    height: 3
  },
  {
    src: "../img/gallery/7.jpeg",
    width: 3,
    height: 4
  },
  {
    src: "../img/gallery/8.jpg",
    width: 4,
    height: 3
  },
  {
    src: "../img/gallery/5.jpeg",
    width: 4,
    height: 3
  }
];