import React from "react";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

import horizontalCss from 'react-animated-slider/build/horizontal.css';

import content  from "./content";
const SliderMain = () => {
	return (				
			<Slider autoplay={2000} classNames={horizontalCss}>
				{content.map((item, index) => (
					<div
						key={index}
						style={{ background: `url('${item.image}') no-repeat center center` }}
					>
						<div className="center">
							<h1>{item.title}</h1>
							<p>{item.description}</p>
							<button>{item.button}</button>
						</div>
					</div>
				))}
			</Slider>		
	);
};

export default SliderMain;