import React from "react";

const EventsList = () => {
	

	return (
		<div className="app">
			<h1> School Calendar Events ! Coming soon...</h1>
			<div className="container">
			Coming soon...
			</div>	
		</div>
	);
};

export default EventsList;