import React from "react";
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Filter from './Filter';
import Movie from './Movie';

import "../../assets/css/gallery.css";

//import Gallery1 from "react-photo-gallery";

//import Lightbox  from "react-image-lightbox";
import 'react-image-lightbox/style.css';
const images = [
  {
	id:0,
    title: 'Birthday Celebration',
    caption: 'Birthday Celebration',
	category_ids: [0,1],
    smallsrc: './img/gallery/thumb1.jpg',
	largesrc: './img/gallery/1.jpeg',
  },
  {
	id:1,
    title: 'Birthday Celebration',
    caption: 'Birthday Celebration',
	category_ids: [1, 2],
    smallsrc: './img/gallery/thumb2.jpg',
	largesrc: './img/gallery/2.jpeg',
  },
  {
	id:2,
    title: 'MPS Birthday Celebration',
    caption: 'Caption 3',
	category_ids: [1, 2],
    smallsrc: './img/gallery/thumb3.jpg',
	largesrc: './img/gallery/3.jpeg',
  },
  {
	id:3,
    title: 'MPS',
    caption: 'Caption 4',
	category_ids: [1, 3],
    smallsrc: './img/gallery/thumb4.jpg',
	largesrc: './img/gallery/4.jpeg',
  },
  {
	id:4,
    title: 'MPS Birthday Celebration and Sweets Celebration',
    caption: 'Caption 5',
	category_ids: [2, 3],
    smallsrc: './img/gallery/thumb5.jpg',
	largesrc: './img/gallery/5.jpeg',
  },
  {
	id:5,
    title: 'MPS Birthday Celebration and Sweets Celebration',
    caption: 'Caption 6',
	category_ids: [2, 3],
    smallsrc: './img/gallery/thumb6.jpg',
	largesrc: './img/gallery/6.jpeg',
  },
  {
	id:6,
    title: 'Celebrations',
    caption: 'Caption 7',
	category_ids: [3],
    smallsrc: './img/gallery/thumb7.jpg',
	largesrc: './img/gallery/7.jpeg',
  },
];
const Gallery = () => {
	
  const [popular, setPopular] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeGenre, setActiveGenre] = useState(0);
  
  const fetchPopular = async () => {
    // i haven't used .env file because its free api key     
    const response = await fetch("https://api.themoviedb.org/3/movie/popular?api_key=1af8f5a0dac921ed793eaf9b1a89b23e&language=en-US&page=1");
    const movies = await response.json();
    setPopular(movies.results);
    setFiltered(movies.results);
	
	setPopular(images);
	setFiltered(images);
  }

  useEffect(() => {
    fetchPopular();
  }, []);	


	const [isOpen, setIsOpen] = useState(false);
  //const [imgIndex, setImgIndex] = useState(0);

return (
	<div className="home">
		<motion.h1
			animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
			transition={{
				duration: 5,
				delay: 0.3,
				ease: [0.5, 0.71, 1, 1.5],
			}}
			initial={{ opacity: 0, scale: 0.5 }}
			whileHover={{ scale: 1.2 }}
		>
				<h1><span className="color1">M</span><span className="color2">P</span><span className="color3">S</span><span className="color4"> Gallery </span> </h1>
		</motion.h1>
		
		<Filter 
		  popular={popular}
		  setFiltered={setFiltered}
		  activeGenre={activeGenre}
		  setActiveGenre={setActiveGenre}
		/>
		 <motion.div
			layout 
			className="popular-movies"
		  >

		  		<AnimatePresence>
					  {filtered.map(movie => (
									  
						<Movie 
						  key={movie.id} 
						  movie = {movie}
						  onClick={() => setIsOpen(true)}
						/>
						
						
						
					  ))}
									 
					  
					</AnimatePresence>		

			  
					
		  </motion.div>
		
		{/*
		  <motion.div
			layout 
			className="popular-movies"
		  >
			  
					<AnimatePresence>
					  {filtered.map(movie => (
						<Movie 
						  key={movie.id} 
						  movie = {movie}
						/>
					  ))}
					</AnimatePresence>			
		  </motion.div>
		 */}
	</div>
)
}
export default Gallery;