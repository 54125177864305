import React from "react";
import MenuBar from "../components/sidebar/MenuBar";
const LayoutHeader = () => {
	return (
	<>
		{/*<header className="App-header" id="top_headerSection">
			LayoutHeader
		</header>
		*/}
		<section className="section homepage-add-section sticky-top" id="headerSection">
			<MenuBar/>
		</section>
	</>
	);
};
export default LayoutHeader;