import React, { useState, useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';

import "@fontsource/roboto"; // 
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';



import { Routes, Route } from "react-router-dom";
import {AnimatePresence} from "framer-motion";

import LayoutContent from "./layout/LayoutContent";
import LayoutHeader from "./layout/LayoutHeader";
import LayoutFooter from "./layout/LayoutFooter";


import AboutUs from "./pages/AboutUs";
//import ContactUs from "./pages/ContactUs";
//import NoPage from "./pages/NoPage";
//import Home from "./pages/Home";
//import SliderMain from './components/slider/SliderMain';
import './assets/css/slider.css';
import "bootstrap/dist/css/bootstrap.min.css";

//import OwlCarouselHome from './components/owlcarousel/OwlCarouselHome';
import Gallery from './components/gallery/Gallery';
import ModalGallery1 from './components/modalgallery/ModalGallery1';

import Events from './components/events/EventsList';

import Blogs from './pages/Blogs';
import Vision from './pages/Vision';
import Mission from './pages/Mission';
import Services from './pages/Services';
import ContactForm from './pages/ContactForm';
import {Container} from 'react-bootstrap';

function App() {
	 const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
		 {loading ? (
			<div className="loader-container">
			  <div className="spinner"></div>
			</div>
      	 ) : (	  
		 <>		  
		 <div>
		   
		   <LayoutHeader/>	
		   
		   <Container fluid>
		   
		   
		   <AnimatePresence>
		   <section id="contentSection">
			   <Routes>
				<Route path="/" name="Home" element={<LayoutContent />} />
				<Route path="/home" name="Home" element={<LayoutContent />} />			
				<Route path='/about-us' element={<AboutUs/>} />	
				<Route path='/gallery' element={<Gallery/>} />	
				<Route path='/gallery/gallery-one' element={<ModalGallery1/>} />	
				<Route path='/gallery/gallery-two' element={<ModalGallery1/>} />	
				<Route path='/events' element={<Events/>} />	
				<Route path='/about-us/mission' element={<Mission/>} />	
				<Route path='/about-us/vision' element={<Vision/>} />	
				<Route path='/services' element={<Services/>} />	
				<Route path='/blogs' element={<Blogs/>} />	
				<Route path='/contact-us' element={<ContactForm/>} />	
				<Route path='/login' element={<ContactForm/>} />	
				
				
				<Route
					path="*"
					element={
					  <div>
						<h2>404 Page not found</h2>
					  </div>
					}
				  />	
			  </Routes>		  	
			</section>
			</AnimatePresence>
		  <section className="section homepage-add-section" id="footerSection">
		 	 <LayoutFooter/>	
		   </section>
		    </Container>
		 </div>
		 </>
		)}
   </>
  );
}

export default App;
