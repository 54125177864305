import { motion } from "framer-motion"
import ModalImage from "react-modal-image";

const Movie = ({ movie }) => {
  return (
	<div className="imageBox">		  
    <motion.div 
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
	 
      <h6>{movie.title}</h6>
     {/* <img src={`https://image.tmdb.org/t/p/w500${movie.backdrop_path}`} alt="" />*/}
	  
	  <ModalImage
		  small={movie.smallsrc}
		  large={movie.largesrc}
		  alt= {movie.title}
		  className="myGalleryModal"
		  showRotate= {true}
		/>
	
    </motion.div>
	</div>  
  )
}

export default Movie