import React from "react";

 const Mission = () => {
return (
	<div className="home">
	<h1><span class="color1">M</span><span class="color2">I</span><span class="color3">S</span><span class="color3">S</span><span class="color4">I</span><span class="color6">O</span><span class="color1">N</span></h1>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;Our Mission is a living statement to build knowledge, self-awareness & relationships with people and the environment to seek happy, healthy impactful lives.
</p>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;In the exploration of their environments, kids are positively involved in observation, question, discussion, prediction, analysis, exploration, investigation & experimentation. In this process, they construct, modify and develop a broad range of scientific concepts & ideas.
</p>

	</div>
);
}
export default Mission;