import * as React from "react";
import LayoutWhatWeOffer from './LayoutWhatWeOffer';
import LayoutPopularActivities from './LayoutPopularActivities';

import SliderMain from '../components/slider/SliderMain';
import OwlCarouselHome from '../components/owlcarousel/OwlCarouselHome';

const LayoutContent = () => {
return (
		<>
		  <section className="section homepage-add-section" id="sliderSection">
		  		<SliderMain />
		   </section>
		   <section className="section homepage-add-section" id="carouselSection">
		    	<OwlCarouselHome/>	
			</section>
		  <section className="section pt-5 pb-5 bg-white homepage-add-section">		  	
			  <LayoutWhatWeOffer/>
		  </section>
		  <section className="section pt-5 pb-5 bg-white homepage-add-section products-section">		  	
			  <LayoutPopularActivities/>
		  </section>
		 </>
);
};
export default LayoutContent;