import * as React from "react";
import OwlCarousel from 'react-owl-carousel3';
import CardItem from '../components/owlcarousel/CardItem';
import SectionHeading from '../components/owlcarousel/SectionHeading';
import {Row,Col} from 'react-bootstrap';

import "../assets/css/owlcarousel.css";
import "../assets/css/style.css";

const LayoutPopularActivities = () => {
return (
		<>
			 
			         	<SectionHeading 
			         		heading='Popular Activities'
			         		subHeading='Our Children are precious to us and we opt for the very best for them and this process begins right from the day they are born. Teachers and parents are two important pillars of a childs strong foundation.'
			         	/>
			            <Row>
			               <Col md={12}>
			               	  <OwlCarousel nav loop {...options} className="owl-carousel-four owl-theme">
			                     <div className="item">
			                        <CardItem 
								   		title='Nursury Classes'
										subTitle='Kids best place of growing'
									  	imageAlt='MPS'
									    image='img/list/1.jpg'
									    imageClass='img-fluid item-img'
									    linkUrl='gallery'
									    offerText='Best Fee Offers'
										time='8:00 AM to 1:00 PM'
										price='Morning Batch'
										showPromoted={true}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										rating='3.1 (300+)'
								   	/>
			                     </div>
			                     <div className="item">
								 
			                        <CardItem 
								   		title='Nursury Classes'
										subTitle='Kids best place of growing'
									  	imageAlt='MPS'
									    image='img/list/2.jpg'
									    imageClass='img-fluid item-img'
									    linkUrl='gallery'
									    offerText='Best Fee Offers'
										time='8:00 AM to 1:00 PM'
										price='Morning Batch'
										showPromoted={true}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										rating='3.1 (300+)'
								   	/>
			                     </div>
			                     <div className="item">
			                        <CardItem 
								   		title='Nursury Classes'
										subTitle='Kids best place of growing'
									  	imageAlt='MPS'
									    image='img/list/3.jpg'
									    imageClass='img-fluid item-img'
									    linkUrl='gallery'
									    offerText='Best Fee Offers'
										time='8:00 AM to 1:00 PM'
										price='Morning Batch'
										showPromoted={true}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										rating='3.1 (300+)'
								   	/>
			                     </div>
			                     <div className="item">
			                        <CardItem 
								   		title='Nursury Classes'
										subTitle='Kids best place of growing'
									  	imageAlt='MPS'
									    image='img/list/4.jpg'
									    imageClass='img-fluid item-img'
									    linkUrl='gallery'
									    offerText='Best Fee Offers'
										time='8:00 AM to 1:00 PM'
										price='Morning Batch'
										showPromoted={true}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										rating='3.1 (300+)'
								   	/>
			                     </div>
			                  </OwlCarousel>
			               </Col>
			            </Row>
			        
		 </>
);
}
const options={
	responsive: {
        0:{
            items:1,
        },
        600:{
            items:2,
        },
        1000: {
          items: 4,
        },
        1200: {
          items: 4,
        },
      },

        lazyLoad: true,
        pagination: false.toString(),
        loop: true,
        dots: false,
        autoPlay: 2000,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

export default LayoutPopularActivities;