import React, { useEffect } from 'react';

const Filter = ({ activeGenre, setActiveGenre, setFiltered, popular }) => {
   	
  useEffect(() => {
    if (activeGenre === 0) {
      setFiltered(popular)
      return
    }
    const filtered = popular.filter(movie => movie.category_ids.includes(activeGenre));
    setFiltered(filtered);
  }, [activeGenre])
  

  return (
    <div className="filter-container">
	
      <button 
        className={activeGenre === 0 ? "active" : " tabButton btn btn-outline-primary mx-2 text-capitalize"}
        onClick={() => setActiveGenre(0)}
      >
       All
      </button>
      <button 
        className={activeGenre === 1 ? "active" : " tabButton btn btn-outline-primary mx-2 text-capitalize"}
        onClick={() => setActiveGenre(1)}
      >
       Birthday Celebrations
      </button>
      <button 
        className={activeGenre === 2 ? "active" : " tabButton btn btn-outline-primary mx-2 text-capitalize"}
        onClick={() => setActiveGenre(2)}
      >
        General Activities
      </button>
	  <button 
        className={activeGenre === 3 ? "active" : " tabButton btn btn-outline-primary mx-2 text-capitalize"}
        onClick={() => setActiveGenre(3)}
      >
        Other Activities
      </button>
    </div>
  )
}

export default Filter