import React from "react";

 const Servises = () => {
return (
	<div className="home">
	<h1><span class="color1">S</span><span class="color2">E</span><span class="color3">R</span><span class="color4">V</span><span class="color6">I</span><span class="color1">C</span><span class="color2">S</span></h1>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;The dream of Modern Public School is co-created with the community of teachers, parents, and children to be the benchmark of excellence in education in a changing India. We, therefore, strive to lay the foundation for healthy productive & satisfying life in the future, by enabling the children to develop her/his maximum potential through:-
</p>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;Physical well : being Health & Motor Development, Personal, Social and Emotional Development, Creative, and Aesthetic Development, Language, Communications & Literacy Development, Cognitive development-Environmental Awareness, Development of Scientific Temper and developing Mathematical Thinking & Reasoning.
</p>

	</div>
);
};export default Servises;