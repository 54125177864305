import React from "react";

 const AboutUs = () => {
return (
	<div className="home">
	<h1><span class="color1">A</span><span class="color2">b</span><span class="color3">o</span><span class="color4">u</span><span class="color6">t</span></h1>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;Know about Modern Public School is a Play School, Bilanderpur, Shahpura, Jaipur, is a Coed Day School, which serves the educational needs of hundreds of students from Playgroup through UKG. The classes in Modern Public School is a Play School, Bilanderpur for every academic year begins in April and ends in March.
</p>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;In order to achieve our objective of bringing out and nurturing the latent potential of our malleable kids, it becomes mandatory that every child be paid personal attention to.
Not only the kids, but also the parents have multifarious anxieties and doubts. We, at Turtles, make it sure that we deal with them on an individual basis, where each child and parent is satiated. This concern has always helped us in devising creative ways to enhance the unique adroitness that each child has.
</p>
<p>
&emsp;&emsp;&emsp;&emsp;&emsp;"Children are nation's greatest potential asset. In time they become its writers, scientists, philosophers and decision makers. They are constantly learning every second of the day. So energy and effort put into children to enable them to fulfil their full potential, is an investment in the future."	
</p>
	</div>
);
};export default AboutUs;