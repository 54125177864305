import * as React from "react";
import ProductBox from '../components/owlcarousel/ProductBox';
import { motion } from "framer-motion";
import {Row,Col} from 'react-bootstrap';

const LayoutWhatWeOffer = () => {
return (
		<>
		
			  <h1> What We Offer</h1>
				<p>Why Kids & Their Parent Choose Us</p>
						<Row>
						   <Col md={3} xs={6} px-md={5} style={{marginBottom:'10px'}}>
						   <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }} >
						   	<ProductBox 
						   		image='img/weoffer/weoffer-1.jpg'
						   		imageClass='img-fluid rounded'
						   		imageAlt='product'
						   		linkUrl='#'
						   	/>
							</motion.div>
						   </Col>
						   <Col md={3} xs={6}>
						   <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }} >
						   	<ProductBox 
						   		image='img/weoffer/weoffer-2.jpg'
						   		imageClass='img-fluid rounded'
						   		imageAlt='product'
						   		linkUrl='#'
						   	/>
							</motion.div>
						   </Col>
						   <Col md={3} xs={6}>
						    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }} >
						   	<ProductBox 
						   		image='img/weoffer/weoffer-3.jpg'
						   		imageClass='img-fluid rounded'
						   		imageAlt='product'
						   		linkUrl='#'
						   	/>
							</motion.div>
						   </Col>
						   <Col md={3} xs={6}>
						 	<motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }} >
						   	<ProductBox 
						   		image='img/weoffer/weoffer-4.jpg'
						   		imageClass='img-fluid rounded'
						   		imageAlt='product'
						   		linkUrl='#'
						   	/>
							</motion.div>
						   </Col>
						</Row>
			
		 </>
);
};
export default LayoutWhatWeOffer;